export const ignoredSentryErrors = [
  "Network Error",
  "timeout of 0ms exceeded",
  "Non-Error promise rejection captured with keys: currentTarget, isTrusted, target, type",
  "Loading chunk",
  "Unexpected token '<'",
  /^application '@ottu-mf\/(navigation|frontend|fnb_frontend)' died in status LOADING_SOURCE_CODE/,
  /^application '@ottu-mf\/(navigation|frontend|fnb_frontend)' died in status LOADING_SOURCE_CODE: undefined is not an object \(evaluating 'n.bootstrap'\)/,
];

export const SINGLE_SPA_CSS_TIMEOUT_ERROR_MESSAGE_REGEX =
  /"single-spa-css: While mounting '.*', loading CSS from URL (https?:\/\/[^ ]+) timed out after \d+ms"/;

export const MERCHANT_ADMIN_ROLE = "Merchantadmin";
